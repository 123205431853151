
export const QUERY_KEYS = {
    GET_HOT_TOPIC: 'getHotTopic',
    GET_ADS_BY_ID: 'getAdsById',
    GET_FEED: 'feed',
    GET_META_DATA: 'getMetaData',
    GET_CUSTOMER_MEDIA: "getCustomerMedia",
    GET_COMMENT_FEED: "getCommentFeed"
    // ADD_FAVORITES: 'addFavorites',
    // DELETE_FAVORITES: 'deleteFavorites'
}